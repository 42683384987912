<template>
  <div class="mobile-container">
    <mobileStatusBar mode="gray" title="收支明细" />
    <div class="type-list-item">
      <div class="type-item" :class="Type == 0 ? 'active' : ''" @click="Type = 0">全部</div>
      <div class="type-item" :class="Type == 1 ? 'active' : ''" @click="Type = 1">充值</div>
      <div class="type-item" :class="Type == 2 ? 'active' : ''" @click="Type = 2">购买商品</div>
      <div class="type-item" :class="Type == 3 ? 'active' : ''" @click="Type = 3">接口扣款</div>
      <div class="type-item" :class="Type == 4 ? 'active' : ''" @click="Type = 4">余额转佣金</div>
      <div class="type-item" :class="Type == 5 ? 'active' : ''" @click="Type = 5">佣金转余额</div>
      <div class="type-item" :class="Type == 6 ? 'active' : ''" @click="Type = 6">系统</div>
      <div class="type-item" :class="Type == 7 ? 'active' : ''" @click="Type = 7">退款</div>
      <div class="type-item" :class="Type == 8 ? 'active' : ''" @click="Type = 8">站长为下级创建分站</div>
      <div class="type-item" :class="Type == 20 ? 'active' : ''" @click="Type = 20">分站用户购买商品提成</div>
      <div class="type-item" :class="Type == 21 ? 'active' : ''" @click="Type = 21">分站用户购买分站收益</div>
      <div class="type-item" :class="Type == 22 ? 'active' : ''" @click="Type = 22">提现</div>
    </div>
    <div class="data-list-item">
      <van-list v-model="loading" :finished="finish" @load="handleGetIncomeLogList" loading-text="加载中..." finished-text="" error-text="加载失败">
        <div v-if="finish && !loading && recordList.length == 0" class="empty-item">
          <div class="icon img-cover"><img src="../../assets/images/empty-icon.png" /></div>
        </div>
        <div v-else class="record-list">
          <div class="record-item" v-for="(item, index) in recordList" :key="index">
            <div class="header">
              <div class="content">{{ item.Content }} {{ item.Type == 20 && item.OrderNo ? '订单号：' + item.OrderNo : '' }}</div>
              <div class="money" :class="item.Money > 0 ? 'blue' : 'red'">
                {{ item.Money > 0 ? '+' : '' }}{{ item.Money }}
                <div class="is-rebates" v-if="item.Type == 20 && item.IsRebates == 0">冻结中</div>
              </div>
            </div>
            <div class="footer">
              <div class="time">{{ $TOOL.dateFormat(item.AddTime * 1000) }}</div>
              <div class="balance">余额: {{ item.Money | calcNewMoney(item.OldMoney) }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "BalanceRecord",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      PageCount: 10,
      Page: 1,
      Type: 0,
      loading: false,
      finish: false,
      recordList: []
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  filters: {
    calcNewMoney(money, oldMoney) {
      const now = parseFloat(oldMoney) + parseFloat(money)
      return Math.round(now * 100000) / 100000
    }
  },
  watch: {
    Type(val) {
      this.handleChangeType(val)
    }
  },
  mounted() {

  },
  methods: {
    handleChangeType(type) {
      this.Type = type
      this.Page = 1
      this.finish = false
      this.loading = false
      this.recordList = []
      this.handleGetIncomeLogList()
    },
    async handleGetIncomeLogList() {
      const { PageCount, Page, Type, recordList } = this
      const params = {
        PageCount,
        Page
      }
      if (Type) {
        params.Type = Type
      }
      var res = await this.$API.incomeLogList.post(params)
      for (let index = 0; index < res.info.length; index++) {
        const item = res.info[index]
        res.info[index].Money = Math.round(item.Money * 100000) / 100000
      }
      this.recordList = [
        ...recordList,
        ...res.info
      ]
      this.Page = Page + 1
      this.finish = res.info.length < PageCount
      this.loading = false
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  height: 100vh;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.type-list-item {
  padding: .24rem;
  overflow-y: auto;
  white-space: nowrap;
  .type-item {
    display: inline-block;
    padding: 0 .24rem;
    height: .54rem;
    line-height: .54rem;
    border: .03rem solid #fff;
    border-radius: .3rem;
    background-color: #fff;
    color: #242941;
    font-size: .28rem;
    font-weight: bold;
    margin-right: .24rem;
    &:nth-last-child(1) {
      margin-right: 0;
    }
    &.active {
      border-color: #276eff;
      background-color: rgba(19, 153, 255, .24);
    }
  }
}
.data-list-item {
  flex: 1;
  overflow-y: auto;
  padding: 0 .32rem;
  .record-item {
    margin-bottom: .24rem;
    background-color: #fff;
    border-radius: .16rem;
    padding: .24rem;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-size: .28rem;
      line-height: .4rem;
      .content {
        flex: 1;
        color: #242941;
      }
      .money {
        margin-left: .24rem;
        font-weight: bold;
        &.red {
          color: #ff2442;
        }
        &.blue {
          color: #276eff;
        }
        .is-rebates {
          text-align: right;
          color: #999;
          font-weight: normal;
          font-size: .2rem;
          line-height: .28rem;
        }
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: .16rem;
      line-height: .34rem;
      font-size: .24rem;
      .time {
        color: #ccc;
      }
      .balance {
        font-weight: bold;
        color: #242941;
      }
    }
  }
}
</style>